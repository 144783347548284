import React, { useState } from 'react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  Message,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import '../../themes/default/main.scss'
import FeedbackButtons from '../feedbackbutton/feedbackbutton';
import './MessageComponent.css'

const MessageComponent = ({ msg, index, feedbackReceived, handleFeedback, apiInteractionFinished, isLastBotMessage, handleFormSubmit }) => {
  const [formState, setFormState] = useState({
    my_name: '',
    email: '',
    telno: '',
    cont: '',
  });

  const [errorState, setErrorState] = useState({
    my_name: '',
    email: '',
    telno: '',
    cont: '',
  });

  const validateForm = () => {
    let isValid = true;
    let errors = {
      my_name: '',
      email: '',
      telno: '',
      cont: '',
    };

    // ここでバリデーションを行います
    // 例えば、全てのフィールドが空でないことを確認します
    Object.entries(formState).forEach(([key, value]) => {
      if (value.trim() === '') {
        isValid = false;
        errors[key] = 'このフィールドは必須です';
      }
    });

    setErrorState(errors);
    return isValid;
  };

  const handleInputChange = (event) => {
    let value = event.target.value;
    if (event.target.name === 'cont') {
      value += '\n from GPT';
    }
    setFormState({
      ...formState,
      [event.target.name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      sendFormData(formState);
      handleFormSubmit(event);
    }
  };

  function sendFormData(data) {
    fetch('https://chatbot.interbank.co.jp/query-form.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  if (msg.type === "form") {
    return (
      <form className="feedback-form" onSubmit={handleSubmit}>
      <label className="feedback-label">
        お名前：
        <input type="text" name="my_name" className="feedback-input" onChange={handleInputChange} />
      </label>
      {errorState.name && <div className="error">{errorState.name}</div>}
      <label className="feedback-label">
        メールアドレス：
        <input type="email" name="email" className="feedback-input" onChange={handleInputChange} />
      </label>
      {errorState.email && <div className="error">{errorState.email}</div>}
      <label className="feedback-label">
        電話番号：
        <input type="tel" name="telno" className="feedback-input" onChange={handleInputChange} />
      </label>
      {errorState.tel && <div className="error">{errorState.tel}</div>}
      <label className="feedback-label">
        問合せ内容：
        <textarea name="cont" className="feedback-input" onChange={handleInputChange} />
      </label>
      {errorState.query && <div className="error">{errorState.query}</div>}
      <input type="submit" value="送信" className="feedback-submit" />
    </form>
    );
  }
  
  return (
    <React.Fragment key={index}>
      <Message model={msg} >
        {msg.direction !== "outgoing" && 
          <Avatar src={"/icon_ai.png"} name="Joe" />
        }
        <Message.HtmlContent html= {msg.message} />
      </Message>
      {/* APIとのやり取りが終わった後、かつ最新のボットからのメッセージにのみフィードバックボタンを表示 */}
      {apiInteractionFinished && msg.sender === "Bot" && !feedbackReceived.includes(index) && isLastBotMessage && <FeedbackButtons onFeedback={(feedbackMessage) => handleFeedback(index, feedbackMessage)} />}
    </React.Fragment>
  );
};

export default MessageComponent;