// api.js
export const fetchApiResponse = async (value) => {
  // const response = await fetch("https://chatbot.interbank.co.jp/server.php", {
    const response = await fetch("https://webhook-inter-chatbot.azurewebsites.net/api/main?code=6iVHtVakFlig0ILHBjJNXl5Cw7PDmzy_roZ-GnCtC-t5AzFuCVb9LQ==", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ text: value })
  }
  // )
  // .then(response => response.json())
  // .then(data => console.log(data))
  // .catch((error) => {
  //   console.error('Error:', error);
  // }
  );
  if (!response.headers.get("content-type")?.includes("application/json")) {
    return null;
  }
  // return response.json();
  const data = await response.json();
  return data.message[0].content;
};

export const sendToSpreadsheet = async(questionContent, answerContent, feedbackMessage) => {
  // 現在の日本時間を取得
  const currentTime = new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
  const payload = JSON.stringify({ question: questionContent, answer: answerContent, feedback:feedbackMessage , time: currentTime });
  // const payload = JSON.stringify({ question: questionContent, answer: answerContent });
  const newheaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  };      
  let gasresponse = null; 
  try {
    gasresponse = await fetch("https://script.google.com/macros/s/AKfycbzZH9sU3Y90wyTQvV7S2Aw1NyTAsVEQbn80OyRtl4_97FJkyVeVKQE_qVI4vbACIo5F/exec", {
      method: "POST",
      headers: newheaders,
      body: payload
    });
  } catch (error) {
    console.error('Error:', error);
  }
  return gasresponse;
};