import React from 'react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import '../..//themes/default/main.scss';

const ButtonComponent = ({ button, handleButtonClick }) => {
  return (
    <button 
      onClick={() => handleButtonClick(button)}
      style={{ 
        width: "90%",
        margin: "4px", 
        padding: "10px", 
        backgroundColor: "#027c96", 
        color: "white", 
        border: "none", 
        borderRadius: "20px",
        fontSize: "0.7em", 
        fontWeight: "bold",
        boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
        transition: "all 0.3s ease 0s",
        cursor: "pointer" 
      }}
      onMouseOver={e => {
        e.target.style.backgroundColor = "#027c96";
        e.target.style.boxShadow = "0px 10px 10px rgba(46, 229, 157, 0.4)";
      }}
      onMouseOut={e => {
        e.target.style.backgroundColor = "#027c96";
        e.target.style.boxShadow = "0px 8px 15px rgba(0, 0, 0, 0.1)";
      }}
    >
      {button}
    </button>
  );
};

export default ButtonComponent;