import React from 'react';
import "./feedbackbutton.css";

const FeedbackButtons = ({ onFeedback }) => {
  return (
    <div className="chat-quick-reply-container">
      <div className="feedback-button" onClick={() => onFeedback('役に立った')}>
        <div className="feedback-content">
          <img alt="" src={'/thumbs_up.svg'} style={{width: '16px', height: '16px'}} />
          <span style={{fontSize: '13px', marginLeft: '4px'}}>役に立った</span>
        </div>
      </div>
      <div className="feedback-button" onClick={() => onFeedback('問合せする')}>
        <div className="feedback-content">
          <img alt="" src={'/thumbs_down.svg'} style={{width: '16px', height: '16px'}} />
          <span style={{fontSize: '13px', marginLeft: '4px'}}>問合せする</span>
        </div>
      </div>
    </div>
  );
};

export default FeedbackButtons;