// useChat.js
import { useState } from 'react';

export const useChat = () => {
  const [messages, setMessages] = useState([
    {
      message: "(自動応答)\nご来訪ありがとうございます。ご用件をお選びください。\n\nご質問内容を入力すると、ChatGPTが回答を生成します。",
      sentTime: "just now",
      sender: "Joe",
      // position: "normal",
      direction: "incoming",
    }
  ]);

  const addMessage = (message, sender, direction) => {
    setMessages(prevMessages => [
      ...prevMessages,
      {
        message: message,
        sentTime: "just now",
        sender: sender,
        position: "normal",
        direction: direction
      }
    ]);
  };

  const extractTextFromHTML = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  };

  const addBotMessage = (message, buttonOptions = null, messageType = "html") => {
    setMessages(prevMessages => [
      ...prevMessages,
      {
        message: message,
        type: messageType,
        sentTime: "just now",
        sender: "Bot",
        position: "last",
        direction: "incoming",
        buttonOptions: buttonOptions
      }
    ]);
  };

  // 他の関数...

  return {
    messages,
    addMessage,
    extractTextFromHTML,
    addBotMessage,
    // 他の状態や関数...
  };
};