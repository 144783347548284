import responses from "../responses"

export const handleMessage = async (value, addBotMessage, sendToSpreadsheet, lastSentMessage, lastReceivedMessage, handleApiResponse) => {
  if (value === "在庫の有無") {
    addBotMessage("以下よりお選びください", ["レート提示のある通貨", "レート提示が０の通貨", "掲載のない通貨"]);
  } else if(value === "取引方法"){
    addBotMessage("以下よりお選びください", ["来店取引", "郵送取引（ご購入）", "郵送取引（ご売却）"]);
  } else if(value === "来店取引"){
    addBotMessage("ご来店ですね。以下よりお選びください", ["お申し込み方法", "【ご来店】適用されるレート", "【ご来店】取引可能金額", "【ご来店】ご持参いただく物","はじめに戻る"]);
  } else if(value === "ベトナムドンについて"){
    addBotMessage("以下よりお選びください", ["ベトナムドンの入荷日", "ベトナムドンの金額の算出方法"]);
  } else if(value === "はじめに戻る"){
    addBotMessage("以下からお選びください", ["本日のレート", "営業時間", "在庫の有無", "硬貨・トラベラーズチェックの取り扱い", "取引方法", "ベトナムドンについて"]);
  } else if(value === "郵送取引（ご購入）"){
    addBotMessage("ご購入（日本円→外貨）ですね。以下よりお選びください", ["お申し込み方法", "【ご購入】送料規定","【ご購入】適用されるレート","【ご購入】外貨の到着目安","【ご購入】取引可能金額","【ご購入】その他のお問い合わせ","はじめに戻る"]);
  } else if(value === "【ご購入】その他のお問い合わせ"){
    addBotMessage("ご購入（日本円→外貨）ですね。以下よりお選びください", ["【ご購入】日本円の支払い方法", "【ご購入】郵送取引の手順","【ご購入】キャンセルについて","入金が完了しているか確認したい","【ご購入】その他のお問合せ","はじめに戻る"]); 
  } else if(value === "【ご購入】その他のお問合せ"){ // 上と違うので注意
    addBotMessage("以下よりお選びください", ["【ご購入】外貨の郵送方法", "金種の指定は可能か知りたい","聞きたい質問がない"]); 
  } else if(value === "金種の指定は可能か知りたい"){
    addBotMessage("以下よりお選びください", ["【USドル】100ドル札", "【ベトナムドン】500,000ドン紙幣","【全通貨】小額紙幣","その他の金種"]);  
  } else if(value === "郵送取引（ご売却）"){
    addBotMessage("ご売却（外貨→日本円）ですね。以下よりお選びください", ["お申し込み方法","【ご売却】郵送取引の手順", "【ご売却】外貨の郵送方法","【ご売却】適用されるレート","【ご売却】日本円の受け取り","【ご売却】取引可能金額","はじめに戻る"]);   
  } else if(value === "役に立たなかった"){ 
    await sendToSpreadsheet(lastSentMessage, lastReceivedMessage, "役に立たなかった");
    addBotMessage("お問い合わせしますか？", ["お問い合わせする"]);
  } else if(value === ("問合せする") || value === ("聞きたい質問がない")){
    await sendToSpreadsheet(lastSentMessage, lastReceivedMessage,"役に立たなかった");
    addBotMessage("こちらからお願いいたします。", null, "form");
  } else if (responses[value]) {
    if(value === "役に立った"){
      await sendToSpreadsheet(lastSentMessage, lastReceivedMessage,"役に立った");
    }
    addBotMessage(responses[value]);
  } else {
    await handleApiResponse(value);
  }
};