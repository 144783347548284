import React, { useState } from 'react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  ConversationHeader,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import '../themes/default/main.scss';
import ButtonComponent from './ButtonComponent/ButtonComponent';
import MessageComponent from './MessageComponent/MessageComponent';
import { handleMessage } from './helpers/messageHandler';
import { useChat } from '../hooks/useChat';
import { fetchApiResponse, sendToSpreadsheet } from '../services/api';

const ChatUI = () => {
  const [isTyping, setIsTyping] = useState(false);
  // フィードバックを受け取ったメッセージのIDを追跡するための状態
  const [feedbackReceived, setFeedbackReceived] = useState([]);
  // APIとのやり取りが終わったかどうかを追跡する状態
  const [apiInteractionFinished, setApiInteractionFinished] = useState(false);
  const [lastReceivedMessage, setLastReceivedMessage] = useState(null);
  const [lastSentMessage, setLastSentMessage] = useState(null);
  const { messages, addMessage, extractTextFromHTML, addBotMessage } = useChat();

  const handleSend = async(value) => {
    setIsTyping(true);
    addMessage(value, "You", "outgoing");
    setApiInteractionFinished(false); // APIとのやり取りが始まったことを示す
    
    await handleMessage(value, addBotMessage, sendToSpreadsheet, lastSentMessage, lastReceivedMessage, handleApiResponse);
    
    setIsTyping(false);
  };
  
  const handleApiResponse = async(value) => {
    // メッセージを送信した後で最後に送信したメッセージを更新
    value = extractTextFromHTML(value);
    setLastSentMessage(value);
    // APIからの応答メッセージを取得
    const content = await fetchApiResponse(value);
    if (content === null) {
      setIsTyping(false);
      return;
    }
    // ボットからの応答メッセージを追加
    addBotMessage(content);
    // メッセージを受信した後で最後に受信したメッセージを更新
    setLastReceivedMessage(content);
    setApiInteractionFinished(true); // APIとのやり取りが終わったことを示す
    setIsTyping(false);
  };

  const handleButtonClick = (value) => {

    handleSend(value);
  };

  // フィードバックボタンがクリックされたときに呼び出されるハンドラ
  const handleFeedback = (messageId, feedbackMessage) => {
    setFeedbackReceived(prevFeedbackReceived => [...prevFeedbackReceived, messageId]);
    // フィードバックメッセージをユーザーメッセージとして追加
    handleSend(feedbackMessage);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSend("送信"); // handleSend関数を使用してBOTからメッセージを送信します
  };

  const buttons = ["本日のレート", "営業時間", "在庫の有無", "硬貨・トラベラーズチェックの取り扱い", "取引方法", "ベトナムドンについて"];
  const CustomMessage = ({ buttons, handleButtonClick }) => (
    <div style={{ width:'80%',border: '1px solid #ccc', borderRadius: '5px', padding: '5px', marginTop:'10px', marginBottom: '10px', backgroundColor: '#f5f5f5' }}>
      {buttons.map((button, i) => (
        <ButtonComponent key={i} button={button} handleButtonClick={handleButtonClick} />
      ))}
    </div>
  );
  return (
    <div style={{ position: "relative", height: "auto" }}>
      <MainContainer style={{ width: "100vw", height: "100vh" }}>
        <ChatContainer style={{ width: "100%", height: "100%" }}>
          <ConversationHeader style={{ backgroundColor: "#027c96" }}>
            <ConversationHeader.Content userName="ChatGPTが回答中です！"/>
          </ConversationHeader>
          <MessageList>
            {messages.map((msg, index) => (
              <>
                <MessageComponent key={index}  msg={msg} index={index} feedbackReceived={feedbackReceived} handleFeedback={handleFeedback} apiInteractionFinished={apiInteractionFinished} isLastBotMessage={index === messages.reduce((lastIndex, currentMsg, currentIndex) => currentMsg.sender === "Bot" ? currentIndex : lastIndex, -1)} handleFormSubmit={handleFormSubmit}/>
                {index === 0 && <CustomMessage buttons={buttons} handleButtonClick={handleButtonClick} />}
                {msg.buttonOptions && <CustomMessage buttons={msg.buttonOptions} handleButtonClick={handleButtonClick} />}
              </>
            ))}
            {isTyping && <TypingIndicator style={{ position: 'sticky', bottom: 0 }} content="回答準備中" />}
          </MessageList>
          <MessageInput placeholder="質問を入力してください" onSend={handleSend} attachButton={false}/>
        </ChatContainer>
      </MainContainer>
    </div>
  );
}

export default ChatUI;