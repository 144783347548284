// src/responses.js
export default {
  "本日のレート": "本日のレートは<a href='https://www.interbank.co.jp/' target='_blank'>こちら</a>からご確認ください、全て手数料込みのレートになります。",
  "営業時間": `
  営業時間
  月曜日～金曜日　11時～19時
  土曜祝日　　　　12時～19時
  日曜日　　　　　休業
  `,
  "在庫の有無": "在庫の有無は...",
  "硬貨・トラベラーズチェックの取り扱い": `申し訳ございませんが硬貨（コイン）のお取り扱いはございません。
  弊社お取引は全て紙幣になります。
  
  ・トラベラーズチェックは以下の通貨、引き受けブランドを対象にお買取りいたします。
  
  ＜対象通貨＞
  米ドル
  ユーロ
  日本円
  英ポンド
  カナダドル
  オーストラリアドル
  スイスフラン
  
  ＜引き受けブランド＞
  Thomas Cook
  Thomas Cook JTB
  Thomas Cook SUMITO MOMITUI BANK
  Thomas CookDAIICHI KANGYOU BANK
  Thomas Cook MIZUHO BANK
  VISA INTERPAYMENT
  VISA BARCLAYS BANK
  VISA SUIMITOMO MITUI BANK
  AMERICAN EXPRESS
  
  なお、お買取りレートはHP提示レートの半額となります。また、現物をお預かりし鑑定に1週間程度お時間をいただきます。`,
  "取引方法": "取引方法は...",
  "ベトナムドンについて": "ベトナムドンについて...",
  "役に立った": "ありがとうございます。他にもご不明点があればお気軽にお問い合わせください。",
  "レート提示のある通貨":`
  在庫がございますのでお申し込みフォームよりお申込みください。
  ・ご来店
  <a href='https://www.interbank.co.jp/inquary/' target='_blank'>https://www.interbank.co.jp/inquary/</a>
  ・ご郵送
  <a href='https://www.interbank.co.jp/store/' target='_blank'>https://www.interbank.co.jp/store/</a>`,

  "レート提示が０の通貨":"在庫切れのためお申し込みいただけません。",
  "掲載のない通貨":"TOPページに掲載のない通貨は弊社ではお取り扱いがございません。",
  "お申し込み方法":"お申し込み方法は<a href='https://www.interbank.co.jp/howto/' target='_blank'>こちら</a>をご参照いただき、お申し込みフォームより送信くださいませ。",
  "【ご来店】適用されるレート":"ご来店時のレートが適用されます。",
  "【ご来店】取引可能金額":`
  【ご購入（日本円→外貨）】
  日本円換算で１万円以上～上限なし
  ※ベトナムドン・インドネシアルピアに限り３万円以上～
  
  【ご売却（外貨→日本円）】
  下限なし～上限なし　金額問わず可能です。


  ※お申し込みの際は外貨の金額をご入力ください。
  `,
  "【ご来店】ご持参いただく物":`
  【200万円未満の場合】現金のみご持参ください。

  【200万円以上の場合】現金のほかに顔写真付きの身分証明書をご持参ください（運転免許証、住所記載有のパスポート等）。
  `,
  "ベトナムドンの入荷日":`
  在庫がある場合レートを提示しております。

  在庫切れ時はレートを掲示しておりません。
  次回入荷日はトップページに掲載しますので
  ご確認ください。
  入荷日の午前11時前にレート提示、お申し込み受付開始予定です。


  ★ご来店予約
  ※5,500,000ベトナムドンから承ります。
  <a href='https://www.interbank.co.jp/inquary/' target='_blank'>https://www.interbank.co.jp/inquary/</a>

  ★郵送のお申し込み
  ※１０万円以上から承ります。
  <a href='https://www.interbank.co.jp/store/' target='_blank'>https://www.interbank.co.jp/store/</a>

  ★お取引方法の詳細はこちらです。
  <a href='https://www.interbank.co.jp/howto/' target='_blank'>https://www.interbank.co.jp/howto/</a>
  `,
  "ベトナムドンの金額の算出方法":`
  以下を参考にご自身で計算をお願いいたします。

  ※来店は3万円以上から、郵送は10万円以上から承ります。
  （注文殺到のため最低金額を変更いたしました）
  ※50万ドン単位で承ります。
  ※お申し込みの際はベトナムドンの金額をご入力ください。

  【レート0.005946の場合】

  例1）約3万円分
  30,000円 ÷ 0.005946 = 5,500,000ドン

  例2）約5万円分
  50,000円 ÷ 0.005946 = 8,500,000ドン

  例3）約10万円分
  100,000円 ÷ 0.005946 = 17,000,000ドン
  `,

  "【ご購入】送料規定":`ベトナムドン・インドネシアルピア以外の通貨
  5万円以上～10万円未満 300円
  10万円以上　無料
  
  ※お申し込みの際は外貨の金額をご入力ください。
  
  ※5万円未満はご購入いただけません。
  　（2023/3/10より最低取引金額が変更になりました）
  
  ※ベトナムドン・インドネシアルピアは10万円以上からご購入可能です。
  　（2023/3/16より最低取引金額が変更になりました）
  
  ※日本郵便のレターパックプラスまたは簡易書留にて発送いたします。
  対面でのお受け取り、ご不在の場合は不在票が入ります。`,

  "【ご購入】適用されるレート":`お申し込み時のレートが適用されます。
  18時以降～翌日レート更新までのお申し込みは
  翌日のレートが適用されます。`,

  "【ご購入】外貨の到着目安":`平日１４時までのご入金で最短翌日または翌々日のお届けです。

  金曜日14時から日曜日にかけてのご入金は、最短翌月曜日（祝日の場合は祝日明け）以降に確認し発送いたします。
  
  ※※現在注文殺到のためご希望の到着日より数日遅れてお届けとなる場合がございます。
  お急ぎの方はお申し込み時、最下部の備考欄に「〇月〇日必着希望」とご記入ください。※※
  
  日本郵便/お届け日数を調べる
  <a href='https://www.post.japanpost.jp/send/deli_days/index.html' target='_blank'>https://www.post.japanpost.jp/send/deli_days/index.html</a>
  差出元：163-8799、宛先：お客様の郵便番号を入力してください。
  郵便物の種類はレターパックプラスです。`,

  "【ご購入】取引可能金額":`【郵送取引・ご購入（日本円→外貨）】
  　日本円換算で5万円以上～上限なし（※ベトナムドン・インドネシアルピアを除く）
  　ベトナムドン・インドネシアルピア：日本円換算で10万円以上～上限なしです。
  ※お申し込みの際は外貨の金額をご入力ください。
  
  ※外貨の最低取引金額が3/10より変更になりました。
  ※ベトナムドンの最低取引金額は3/16より変更になりました。
  ※インドネシアルピアの最低取引金額は3/22より変更になりました。`,
 
  "【ご購入】日本円の支払い方法":`弊社指定口座（楽天またはゆうちょ）へのお振込みをお願いいたします。

  ※その他決済方法（クレジットカード等）は対応しておりません。
  
  ※お申し込み確定後、ご請求金額と振込先を記載したメールをお送りいたします。メールご確認後にお振込みください。
  
  ※お振込み期限はご請求メールをお送りした日の翌銀行営業日までです。`,

  "【ご購入】郵送取引の手順":`①お申し込みフォームよりお申し込みください。
  <a href='https://www.interbank.co.jp/store/' target='_blank'>https://www.interbank.co.jp/store/</a>
  
  ②弊社にて在庫を確認し、ご請求金額のメールを送付いたします。
  
  ③弊社指定口座（楽天銀行またはゆうちょ銀行）にお振込みをお願いいたします。
  ※※お振込み期限はご請求メールをお送りした日の翌銀行営業日までです。（金曜～日曜の場合月曜日まで）。
  
  ④お振込み確認後、弊社より外貨を発送いたします。
  
  ※※現在注文殺到のためご希望の到着日より数日遅れてお届けとなる場合がございます。 お急ぎの方はお申し込み時、最下部の備考欄に「〇月〇日必着希望」とご記入ください。※※`,

  "【ご購入】キャンセルについて":`ご入金前のキャンセルは可能です。
  お届けしたメールの返信にてキャンセルの旨ご連絡ください。
  
  ご入金後のキャンセルは承れません。`,

  "入金が完了しているか確認したい":`平日14時までのご入金は当日15時半頃までにご入金サンキューメールをお送りいたします。

  金曜14時以降～日曜・祝日のご入金は翌平日15時半頃までにご入金サンキューメールをお送りいたします。
  
  上記時間を過ぎてもメールが届かない場合、お手数ですがご請求メールの返信にてご連絡ください。`,

  "【ご購入】外貨の郵送方法":`日本郵便のレターパックプラスまたは簡易書留にて発送いたします。
  対面でのお受け取り、ご不在の場合は不在票が入ります。`,

  "【USドル】100ドル札":`指定可能です。お申し込みの際備考欄にご記入ください。

  ※ミャンマー用の場合その旨もご記入ください。`,

  "【ベトナムドン】500,000ドン紙幣":`指定可能です。備考欄にその旨ご記入ください。`,

  "【全通貨】小額紙幣":`注文殺到・小額紙幣の在庫は限られておりますので現在ご要望を承れません。
  発送時の在庫の範囲内でご用意いたします。
  
  お申し込みの際備考欄にご希望内容をご入力ください。
  ご用意可能な場合はご希望に沿ってご用意いたします。
  
  尚、事前にお問い合わせいただきましてもご用意可能な枚数はお伝えいたしかねます。
  
  ご理解・ご協力のほどよろしくお願いいたします。`,

  "その他の金種":`発送時の在庫内で可能な場合のみご用意いたします。

  備考欄にご希望内容をご記入ください。
  
  ご理解・ご協力の程よろしくお願いいたします。`,

  "【ご売却】郵送取引の手順":`①お申し込みフォームよりお申し込みください。
  <a href='https://www.interbank.co.jp/store/' target='_blank'>https://www.interbank.co.jp/store/</a>
  ②弊社から郵送先などを記載した詳細メールをお送りいたしますのでご確認ください。
  ③外貨を郵送してください。
  ④弊社到着後お客様のご口座に入金いたします。  
`,
  "【ご売却】外貨の郵送方法":`郵便局より一般書留または簡易書留にて郵送してください。

  外貨郵送の送料はお客様ご負担です。 日本郵便/料金計算は<a href='https://www.post.japanpost.jp/send/fee/simulator/index.html' target='_blank'>こちら</a>です。`,

  "【ご売却】適用されるレート":`弊社到着日のレートが適用されます。`,

  "【ご売却】日本円の受け取り":`外貨と同封していただく買取申込書（またはメモ）記載のご口座にお振込みいたします。

  ★振込日目安：外貨到着後当日または翌営業日
  ★弊社からの日本円お振込みのご連絡・明細の発行は割愛させていただいておりますのでご口座のご確認をお願いいたします。
  ご理解、ご協力のほどよろしくお願いいたします。
  ★銀行振込以外の方法は承れません。`,

  "【ご売却】取引可能金額":`【郵送取引・ご売却（外貨→日本円）】
  下限なし～上限なし　金額問わず可能です。
  
  ※お申し込みの際は外貨の金額をご入力ください。`,

  "送信":`お問い合わせありがとうございました。
  いただきましたメールアドレスに営業時間中に回答いたします。
  
  ※弊社より返信がない場合は、ご入力いただいたメールアドレスをご確認ください。
  
  ※迷惑メールフィルタが有効の場合は設定を解除し、
  【info@interbank.co.jp】からメールを受信できるよう設定してください。`,
};